<template>
    <div style="background: #F5F5F5;" >
        <div class="goodsList_warp_box" >
            <div class="goodsList_box"   >
                <div class="filtrate">
                    <dl class="clearfix">
                        <dt class="fl">分类：</dt>
                        <div class="clearfix fl" style="width: 1060px">
                            <dd :class="{active :checkOne==0}" @click="getTwoClass(1)">全部</dd>
                            <dd  :class="{active :checkOne != 0 && checkOne == item.cate_id }" v-for="(item,index) in cateGorys" :key="index"  @click="getTwoClass(0,item.cate_id,index)">{{item.cate_name}}</dd>
                        </div>
                    </dl>
                    <dl class="clearfix" v-show="checkOne !=0">
                        <dt class="fl">类别：</dt>
                        <dd :class="{active :checkTwo==0}" @click="getTwoId(1)" >全部</dd>
                        <dd  :class="{active :checkTwo != 0 && checkTwo == item.cate_id }" v-for="(item,index) in twoClass"  :key="index" @click="getTwoId(0,item.cate_id)">{{item.cate_name}}</dd>
                    </dl>
                    <dl class="clearfix">
                        <dt class="fl">折扣：</dt>
                        <dd  :class="{active :checkDiscount == item.id }" @click="getDiscount(item.id)" v-for="(item,index) in discount" :key="index">{{item.name}}</dd>
                    </dl>
                    <dl class="clearfix" >
                        <dt class="fl">排序：</dt>
                        <dd  :class="{active :checkSort == item.id }" @click="seleTriangle(index,item.id)" v-for="(item,index) in sortMain" :key="index">{{item.name}}
                            <div class="iconwrap" v-if="index>0">
                                <icon name="iconsanjiaoxia" size="2px"  :color="checkSort==item.id && isCheck ? '#EC1430':'#666666'" class="icon"></icon>
                                <icon name="iconsanjiaoshang" size="2px" :color="checkSort==item.id && !isCheck ? '#EC1430':'#666666'" class="icon"></icon>
                            </div>
                        </dd>
                    </dl>
                </div

                >
                <div class="goodsList">
                    <ul class="clearfix">
                        <li v-for="item in dataList">
                            <GoodsItem  :imgWidth="213+'px'"  :comeStyle="comeStyle" :goodsDetail="item"></GoodsItem>
                        </li>
                    </ul>
                    <div class="mt30">
                        <el-pagination @current-change ='handleCurrentChange'
                                :hide-on-single-page="total< limit"
                                background
                                :current-page.sync="page"
                                :page-size="limit"
                                layout="prev, pager, next"
                                :total="total">
                        </el-pagination>
                    </div>
                    <div></div>
                    <!--暂无数据 start-->
                    <div class="nullData mt20" v-show="nullGoods">
                        <img src="../../assets/images/null_data.png" alt="">
                    </div>
                    <!--暂无数据 end-->
                </div>
            </div>
        </div>
        <!--   底部    -->
        <myFooter ></myFooter>

    </div>
</template>
<style lang="less" scoped>
    .iconwrap {
        display: inline-block;
        position: relative;
        top:2px;
        width: 10px;
        height: 20px;
        /*background: green;*/
        .icon {
            position: absolute;
        }
        .icon:first-child {
            top:-14px;
            left: 0;
        }
        .icon:last-child {
            bottom: -19px;
            left: 0;
        }
    }
    .goodsList{
        min-height: 400px;
    }
    .goodsList_warp_box{
        padding-top: 20px;
    }
    .goodsList_box{
        width: 1200px;
        margin: 0 auto;
        background: #F5F5F5;
        z-index: 999;
    }
    .filtrate{
        width: 1200px;
        background: #fff;
        dl{
            border-bottom: 1px dotted #EEEEEE;

            line-height: 59px;
            margin: 0 20px;
            &:last-child{
                border-bottom: 0 none;
            }
            dt{
                color: #333333;
                margin-right: 58px;
                font-size:14px;
            }
            dd{
                cursor: pointer;
                float: left;
                color: #666666;
                font-size: 14px;
                margin-right: 44px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .filtrate dd.active{
        color:#EC1430;
        border:0 none;
    }
    .goodsList{
        padding-bottom: 40px;
        li{
            float: left;
            width: 224px;
            height: 322px;
            background: #fff;
            margin-right: 20px;
            margin-top: 20px;
            &:nth-child(5n){
                margin-right: 0;
            }
        }
    }
    .swiper-container{
        padding-left: 30px !important;
    }
    .many{
        background: #fff;
        li{
            padding: 30px 40px;
            i{
                width: 1px;
                height: 270px;
                position: absolute;
                right: 0;
                top:0;
                background: url(../../assets/images/img_line.png) 0 0 no-repeat;
            }
            &:last-child i{
                display: none;

            }
        }
    }
    .swiper-button-prev{
        left: 0;
        z-index: 999;
        width:38px;
        height: 109px;
        background:url(../../assets/images/icon_prve.png) 0 0 no-repeat;
    }
    .swiper-button-next{
        width:38px;
        height: 109px;
        background:url(../../assets/images/icon_next.png) 0 0 no-repeat;
        right: 0;
    }
    /*.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{*/
        /*opacity: 1;*/
    /*}*/
    .special_sale{
        height: 76px;
        line-height: 76px;
        background: #fff;

        h2{
            color: #333333;
            font-size: 30px;
            font-weight: bold;
            i{
                width: 42px;
                height: 22px;
                display: inline-block;
                background: url(../../assets/images/icon_nine_title.png) center center no-repeat;
                &:last-child{
                    background: url(../../assets/images/icon_nine_rg.png) center center no-repeat;
                }

            }

        }

    }
    .nullData{
        height:396px;
        text-align: center;
        background: #fff;
        padding-top: 120px;
    }
</style>
<script>
    import { getBrandGoods,GLdegioosstt} from '@/global/https'
    import icon from '@/components/icon/iconfont.vue'
    import GoodsItem from '@/components/GoodsItem.vue'
    import footer from '@/components/footer'
    import {cateGory} from '@/views/filtrateList/filtrate'
    import {getGoodsList} from '@/views/homePage/home'
    export default {
          components: {
                GoodsItem,
                icon,
              myFooter:footer, // 底部
          },
          data(){
                return{
                     comeStyle:{
                           alignCent:'center',
                           line:1, //2 默认一行隐藏 2为2行隐藏
                     },
                      swiperOption:{
                            navigation: {
                                  nextEl: '.swiper-button-next',
                                  prevEl: '.swiper-button-prev'
                            }
                      },
                      discount:[// 折扣
                            {
                                  id:0,
                                  name:'全部'
                            },
                            {
                                  id:1,
                                  name:'1折以下 '
                            },
                            {
                                  id:2,
                                  name:'1-2折 '
                            },
                            {
                                  id:3,
                                  name:'2-3折'
                            },
                            {
                                  id:4,
                                  name:'3-4折'
                            },
                            {
                                  id:5,
                                  name:'4折以上'
                            }
                      ],
                      sortMain:[
                            {
                                  name:'全部',
                                  id:0,
                            },
                            // {
                            //     name:'销售额',
                            //     id:1,
                            // },
                            {
                                  name:'销量',
                                  id:4 //销量多=>少
                            },
                            {
                                  name:'选择上架量',
                                  id:6
                            },
                            {
                                  name:'上新时间',
                                  id:7 //上架时间 正序
                            },
                            {
                                  name:'价格',
                                  id:2 //价格正序
                            }
                      ],
                      cateGorys:[],//商品分类
                      twoClass:[],//二级分类
                      checkOne:0,//默认选中一级分类 全部
                      checkTwo:0,//选中二级分类 0 全部
                      checkDiscount:0,//选中折扣
                      checkSort:0,//选中排序
                      currentType:6,
                      dataList:[],
                      limit:20,
                      goods_name:'', //搜索关键词
                      isNine:0,
                      page:1,
                      nullGoods:false,//没有商品展示
                      myOrder:0,//99专区 排序order
                      isCheck:true,
                      total:1
                }
          },
        watch:{
            '$route'(to,from){
                if(to.query.name){

                      this.goods_name = to.query.name;
                      this.checkOne = 0;
                      this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);

                }else{
                      this.goods_name='';
                }

            }
        },
        mounted(){
            this.cateGory();
            if(this.$route.query.name){
                this.goods_name = this.$route.query.name;
            }
            this.currentType = this.$route.query.type;//当前专区 买手推荐6
              if(this.$route.query.cateId && this.$route.query.belowID){
                    this.checkOne = this.$route.query.cateId;
                    this.checkTwo = this.$route.query.belowID;
              }

            this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
        },
        methods:{
            async cateGory(){
                    let _this= this;
                     let {code,msg,total,pageNum,rows} = await getBrandGoods({});
                    if (code == 0) {
                          this.cateGorys= rows;
                          if(this.$route.query.cateId && this.$route.query.belowID){
                                this.checkOne = this.$route.query.cateId;
                                this.checkTwo = this.$route.query.belowID;
                                this.cateGorys.forEach(function(item,index){
                                    if(item.cate_id == _this.checkOne){
                                          _this.twoClass =_this.cateGorys[index].next;
                                    }
                                })
                          }
                    }
              },
            async getGoodsList(order,limit,cateId1,brandId,goodsName,isNine,price,page,cateId2){

                let  {code,total,rows,pageNum}= await GLdegioosstt(
                      {
                            order:order,
                            limit:limit,
                            'cate_id_1':cateId1,
                            'brand_id':brandId,
                            'goods_name':goodsName,
                            'is_nine':isNine,
                            price:price,
                            page:page,
                            pageSize:limit,
                    pageNum:page,
                            'cate_id_2':cateId2,
                            total:1,
                      });
 
                if(code==0 && rows.length){
                      this.dataList = rows;
                      this.nullGoods = false;
                    this.total=total; 
                }else{
                      this.dataList=[];
                      this.nullGoods = true;
                      this.total=0;
                }
            },
            getTwoClass(isAll,id,index){

                  this.goods_name='';

                  this.checkTwo=0;
                  this.checkDiscount =0; //选中折扣为0
                  if(isAll == 1){
                        this.checkOne =0;
                  }else{
                        this.twoClass =this.cateGorys[index].next;
                        this.checkOne = id;
                        // alert(this.checkOne)
                  }
                  this.$router.push({path:'/scarchList'})

                  this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
            },
            getTwoId(isAll,id){
                  if(isAll == 1){
                        this.checkTwo =0
                  }else {
                        this.checkTwo = id;
                  }

                  this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
            },
            getDiscount(id){
                this.checkDiscount = id;

                this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
            },
            seleTriangle(index,id){
                this.checkSort = id;
                this.isCheck = !this.isCheck;

                if(!this.isCheck){
                    this.myOrder = id;
                }else if(this.isCheck && id == 4){
                    this.myOrder = 5;
                }else if(this.isCheck && id == 7){
                    this.myOrder = 3; //上架时间倒叙
                }else if(this.isCheck && id == 2){
                    this.myOrder = 1;//价格倒叙
                }

                this.getGoodsList(this.myOrder,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
            },
              handleCurrentChange(val) {
                    this.page = val;
                    this.getGoodsList(this.currentType,this.limit,this.checkOne,0,this.goods_name,this.isNine,this.checkDiscount,this.page,this.checkTwo);
              }
        }
    }
</script>
